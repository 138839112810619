import "@fontsource/ubuntu/400.css"
import "@fontsource/ubuntu/500.css"
import "@fontsource/ubuntu/700.css"
import 'public/style.scss'
import Cookies from 'js-cookie';
import 'core-js';
import 'public/main'
import ahoy from "ahoy.js"
import Rails from "@rails/ujs"
Rails.start();

window.jQuery = $;
window.$ = $;
window.Cookies = Cookies;

