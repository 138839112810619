import AOS from 'aos';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade , Thumbs } from 'swiper';


Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);
window.Swiper = Swiper;

// JavaScript
$(document).ready( function () {
  AOS.init({
    disable: function() {
      var maxWidth = 767;
      return window.innerWidth < maxWidth;
    },
    once: true,
    anchorPlacement: 'center-bottom',
  });

  //scroll top
  // $('.back-to-top').click(function(event){
  //   $("html,body").animate({scrollTop: 0}, 1000);
  // });

  // transparent header
  function headerScroll() {
    if ($(window).scrollTop() > 100) {
      $('.header').addClass("min-header");
    } else {
      $('.header').removeClass("min-header");
    }
  }
  headerScroll();
  $(window).on('scroll', function () {
    headerScroll();
  });

  $('.header-mobile-menu').on('click', function () {
    $(this).toggleClass('active');
    $('.header-menu-outer').toggleClass('active');
  });

  $('.menu-link').click(function(e) {
      $(this).parent().toggleClass('active').siblings().removeClass('active');
  });

  // 首頁輪播
  var indexBanner = new Swiper('#indexBanner', {
    autoplay: {
      delay: 2000,
    },
    spaceBetween: 20,
    slidesPerView: 1,
    loop: true,
    centeredSlides: true,
    speed: 1000,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    breakpoints: {
      1024: {
        slidesPerView: "auto",
        spaceBetween: 20,
      },
    },
  });

  // 首頁走進現場
  var indexStories = new Swiper('#indexStories', {
    spaceBetween: 24,
    slidesPerView: 1,
    autoplay: true,
    loop: true,
    speed: 1000,
    navigation: {
      nextEl: ".swiper-button-next-custom",
      prevEl: ".swiper-button-prev-custom",
    },
    breakpoints: {
      991: {
        slidesPerView: 3,
      },
      767: {
        slidesPerView: 2,
      },
    },
  });
});
